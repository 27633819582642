<template>
  <p class="static-text__input">{{ staticText }}</p>
  <input
    :type="type ? type : 'text'"
    class="input-field"
    @blur="onFocusOut"
    @focusin="onFocus"
    @keypress="isNumber"
    :value="value"
    :placeholder="placeholder ? placeholder : 'Preis eingeben'"
    @input="changeValue"
    ref="inputField"
  />
  <slot></slot>
</template>

<script src="./InputField.ts"></script>

<style lang="scss">
@import "src/styles/index.scss";
.static-text__input {
  position: absolute;
  z-index: 1;
  left: 90%;
  top: 14px;
  color: $primary;
}
</style>
