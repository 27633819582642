export default {
  name: "input-field",
  props: {
    value: Number,
    placeholder: String,
    type: String,
    focused: Boolean,
    current: Number,
    staticText: String
  },
  data: () => ({
    localFocus: false
  }),
  methods: {
    isNumber: function(evt) {
      const charCode = evt.keyCode ? evt.keyCode : evt.charCode;
      if (this.$refs.inputField.value.includes(".") && charCode === 46) {
        evt.preventDefault();
      }
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return false;
      }
    },
    onFocus: function() {
      this.$emit("focus-in", true);
    },
    onFocusOut: function() {
      this.$emit("focus-out", false);
    },
    changeValue: function() {
      const diff =
        Math.round(this.$refs.inputField.value) -
        Number(this.$refs.inputField.value);
      if (diff <= 0.001 && diff > 0) {
        this.$refs.inputField.value = Math.round(this.$refs.inputField.value);
      }
      if (
        this.$refs.inputField.value.includes(".") &&
        this.$refs.inputField.value
          .toString()
          .split(".")[1]
          .includes("999")
      ) {
        const index = this.$refs.inputField.value.indexOf("999");
        this.$refs.inputField.value = Number(
          this.$refs.inputField.value
        ).toFixed(index);
      }
      if (this.current) {
        this.$emit(
          "change-value",
          Number(this.$refs.inputField.value),
          this.current
        );
        return;
      }
      this.$emit("change-value", Number(this.$refs.inputField.value));
    }
  }
};
