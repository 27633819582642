<template>
  <div class="wrapper__footer">
    <p class="text__gray">
      **Die Berechnungen dienen der Orientierung und sind nicht verbindlich. Ein
      rechtlicher Anspruch auf die berechneten Werte sind nicht ableitbar. Die
      Werte sollen eine Einschätzung geben, mit welchen monatlichen Aufwand eine
      Wasserfilteranlage bereits finanzierbar ist. Die ausgegebenen Werte sind
      näherungsweise und können von den tatsächlich vertraglichen Werten
      abweichen. Jedoch werden diese vor Abschluss einer Finanzierung oder
      Leasing genau ermittelt und dann vertraglich fixiert.
      <br />
      <br />
      <a class="link" :href="link">{{ linkText }}</a>
      {{ text }}
    </p>
  </div>
</template>

<script src="./TextParagraph.ts"></script>

<style scoped lang="scss">
@import "src/styles/index";
@import "src/styles/mixin";
.wrapper__footer {
  margin-top: 22px;
  display: flex;
  justify-content: flex-end;
  @include md {
    justify-content: center;
    padding-bottom: 110px;
  }
}
.text__gray {
  color: $dark_gray;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.5;
  flex-basis: 83%;
}

.link {
  color: $primary;
  opacity: 0.8;
  transition: linear 0.4s;
  &:hover {
    opacity: 1;
  }
}
</style>
