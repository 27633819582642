<template>
  <button
    class="option-button"
    :class="isActive ? 'option__active' : 'option__passive'"
  >
    {{ text }}
  </button>
</template>

<script src="./OptionButton.ts" lang="ts"></script>

<style scoped lang="scss">
@import "src/styles/mixin";
.option-button {
  padding: 11px 46px;
  border: none;
  cursor: pointer;
  outline: none;
  @include xs {
    padding: 11px 17px;
  }
}
.option__active {
  background-color: #be0064;
  color: #fafafa;
}
.option__passive {
  background-color: #e9e9e8;
  color: #808080;
}
</style>
