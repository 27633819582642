import InputField from "../input_field/InputField";
import SelectItem from "../select/SelectItem";

export default {
  name: "grid-form",
  components: { InputField, SelectItem },
  props: {
    isMobile: Boolean,
    optionData: Array,
    row1: Array,
    row2: Array,
    row3: Array,
    months: Array,
    tab: Number,
    currency: String
  },
  data: () => ({
    titleTexts: [
      {
        class: "empty",
        text: "Laufzeit (Monate)",
        opened: false,
        selectedItem: null
      },
      {
        class: "input-field",
        text: "Laufzeit auswählen",
        opened: false,
        selectedItem: 60
      },
      {
        class: "input-field",
        text: "Laufzeit auswählen",
        opened: false,
        selectedItem: 60
      },
      {
        class: "input-field",
        text: "Laufzeit auswählen",
        opened: false,
        selectedItem: 60
      }
    ],
    timePeriods: [
      { name: ["Monatlich", "netto"], data: [] },
      { name: ["Endbetrag", "netto"], data: [] }
    ],
    focusedInput: false,
    gridForm: ""
  }),
  beforeMount() {
    this.timePeriods[0].data = this.row1;
    this.timePeriods[1].data = this.row2;
  },
  methods: {
    focusOut: function(i) {
      if (typeof i !== "number") {
        return;
      }
      if (this.isMobile) {
        this.titleTexts[this.titleTexts.length - 1].opened = false;
      }
      this.titleTexts[i + 1].opened = false;
    },
    focusIn: function(i, useFocusOut) {
      if (this.isMobile) {
        if (useFocusOut && this.titleTexts[this.titleTexts.length - 1].opened) {
          this.focusOut(i);
          return;
        }
        this.titleTexts[this.titleTexts.length - 1].opened = true;
        return;
      }
      if (useFocusOut && this.titleTexts[i + 1].opened) {
        this.focusOut(i);
        return;
      }
      this.titleTexts[i + 1].opened = true;
    },
    selectItem: function(item, current) {
      this.titleTexts[current].selectedItem = item;
      // console.log(item, current, this.titleTexts[current]);
      this.$emit("change-month", item, current);
    },
    changeValue: function(value, current) {
      if (this.optionData.includes(value)) {
        this.$emit("change-month", value, current);
      }
    }
  },
  watch: {
    row1: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.timePeriods[0].data = newVal;
      }
    },
    row2: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.timePeriods[1].data = newVal;
      }
    },
    tab: {
      immediate: true,
      deep: true,
      handler(currentTab) {
        for (let i = 0; i < this.timePeriods.length; i++) {
          this.timePeriods[i].name[1] = currentTab === 1 ? "netto" : "brutto";
        }
        for (let i = 0; i < this.months.length; i++) {
          this.$emit("change-month", this.optionData[0], i);
        }
      }
    }
  }
};
