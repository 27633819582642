<template>
  <div class="main-wrapper">
    <static-header :isMobile="isMobile" />
    <calculator-main
      @make-finance="makeFinance"
      @make-leasing="makeLeasing"
      :isMobile="isMobile"
    />
    <text-paragraph :is-leasing="isLeasing" />
  </div>
</template>

<script src="./App.ts"></script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,900;1,400;1,800&display=swap");
@import "~normalize.css";
@import "styles/mixin";

body {
  background-color: #fafafa;
}
#app {
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-wrapper {
  max-width: 1096px;
  @include md {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
</style>
