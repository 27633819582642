<template>
  <div class="option-button__wrapper">
    <option-button
      @click="changeTab(1)"
      text="Leasing"
      :is-active="tab === 1"
    ></option-button>
    <option-button
      @click="changeTab(2)"
      text="Finanzierung"
      :is-active="tab === 2"
    ></option-button>
  </div>
  <div class="wrapper">
    <div style="margin-bottom: 14px" v-if="tab === 1">
      <p class="warning__text">
        Leasing bieten wir für Gewerbekunden an.
      </p>
    </div>
    <div style="margin-left: 27px">
      <span style="font-size: 15px">Kaufpreis</span>
      <span
        style="font-size: 12px"
        :style="tab === 1 ? 'margin-right: 41px' : 'margin-right: 35px'"
      >
        {{ tab === 1 ? " (netto)" : " (brutto)" }}</span
      >
      <span class="price-input__wrapper">
        <input-field
          :static-text="currency"
          :value="price"
          @change-value="changePrice"
        />
      </span>
    </div>
    <grid-form
      :option-data="uiMonths"
      :row1="rowOne"
      :row2="rowTwo"
      :is-mobile="isMobile"
      :months="months"
      :tab="tab"
      @change-month="changeCurrentMonthCount"
      :currency="currency"
    />
  </div>
</template>

<script lang="ts" src="./CalculatorMain.ts"></script>

<style scoped lang="scss">
@import "src/styles/index";
@import "src/styles/mixin";
.wrapper {
  padding-top: 25px;
  padding-bottom: 55px;
}
.warning__text {
  color: $dark_gray;
  font-size: 12px;
  margin-left: 16.7%;
  @include xs {
    margin-left: 27px;
  }
}
.price-input__wrapper {
  width: 340px;
  display: inline-flex;
  position: relative;
  @include xs {
    width: 260px;
  }
}
.option-button__wrapper {
  margin-left: 16.7%;
  margin-top: 30px;
  @include xs {
    margin-left: 15px;
  }
}
</style>
