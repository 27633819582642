<template>
  <form @submit="onSubmit" style="width: 100%" v-click-outside="clickOutside">
    <div class="option-selected">
      <slot></slot>
    </div>
    <transition name="grow">
      <div
        v-if="isOpen && selected"
        @click="selected === true"
        style="width: -webkit-fill-available"
        class="dropdown dropdown__animation"
      >
        <ul class="list">
          <li
            :class="isSelected[key] ? 'selected' : ''"
            @click="onSelect(i, key)"
            v-for="(i, key) in localItems"
            :key="i"
          >
            {{ i }}
          </li>
        </ul>
      </div>
    </transition>
  </form>
</template>

<script src="./SelectItem.ts"></script>

<style lang="scss" scoped>
@import "src/styles/index";
.option-selected {
  position: relative;
  margin: 0 !important;
  padding: 0;
  overflow: hidden;
  cursor: text;
}

.dropdown {
  //padding: 20px 0;
  position: absolute;
  z-index: 999;
  background-color: #ffffff;
  width: 100%;
  width: -moz-available; /* For Mozzila */
  border-left: 1px solid $primary;
  border-right: 1px solid $primary;
  border-bottom: 1px solid $primary;
  top: 100%;

  .list {
    padding: 0;
    margin: 0;
    width: 100%;
    .selected {
      background-color: $secondary;
    }
    li {
      cursor: pointer;
      list-style-type: none;
      padding: 12px 49px 12px 19px;
      transition: ease-in-out 0.4s;
      &:hover {
        background-color: $secondary;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.grow-enter-active {
  animation: growDown 0.5s;
}

.grow-leave-active {
  animation: growDown reverse;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  80% {
    transform: scaleY(1.1);
  }

  100% {
    transform: scaleY(1);
  }
}
</style>
