export default {
  name: "text-paragraph",
  props: {
    isLeasing: Boolean
  },
  data: () => ({
    textLeasing:
      " entwickelt seit 1978 Finanzierungslösungen für den Mittelstand. GRENKE schafft den finanziellen Rahmen und Freiraum durch Klein- und Kleinstverträgen und sorgt dadurch für unternehmerische Lösungen, die begeistern.",
    textFinance:
      "  ist die Partnerbank für zahlreiche Branchen. Sie unterstützt den mittelständischen Fachhandel und das Handwerk. Mit maßgeschneiderten Konzepten und Beratung auf Augenhöhe ist die CRONBANK ein fairer und ehrlicher Partner. Der derzeitige Zinssatz effektiv p.a. beträgt 5,90%. Stand 26.04.2011. Die Cronbank ist berechtigt, die Konditionen nach den Marktgegebenheiten jederzeit zu ändern. "
  }),
  computed: {
    text: function() {
      return this.isLeasing ? this.textLeasing : this.textFinance;
    },
    link: function() {
      return this.isLeasing
        ? "https://www.grenke.de/hinterlegen"
        : "https://www.cronbank.de/hinterlegen";
    },
    linkText: function() {
      return this.isLeasing ? "Die GRENKE Bank " : "Die CRONBANK";
    }
  }
};
