<template>
  <div class="grid">
    <div class="span">
      <span>
        {{ titleTexts[0].text }}
      </span>
    </div>
    <div
      style="position: relative"
      v-for="(t, i) in titleTexts.slice(isMobile ? 3 : 1)"
      :key="t"
    >
      <select-item
        :searched-number-item="months[i]"
        :current="i"
        @on-select="selectItem"
        :value="months[i]"
        @focus-out="focusOut"
        :items="optionData"
        :is-open="t.opened"
      >
        <input-field
          @change-value="changeValue"
          :current="i"
          :value="months[i]"
          @focus-in="focusIn(i)"
          placeholder="Laufzeit auswählen"
        >
          <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'arrow pointer'" v-bind:svg-inline="''" @click="focusIn(i, true)" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12.128 1.536a.957.957 0 011.33-.031c.376.35.39.931.032 1.298L7.858 8.487a.989.989 0 01-1.374-.03L.886 2.803a.903.903 0 01.03-1.298.957.957 0 011.33.029l4.589 4.555a.5.5 0 00.704 0l4.59-4.553z" fill="#BE0064"/></svg>
        </input-field>
      </select-item>
    </div>
  </div>
  <div
    :class="index % 2 === 0 ? 'even_row' : 'odd_row'"
    v-for="(timePeriod, index) in timePeriods"
    :key="index"
    class="wrapper"
  >
    <div class="time-period">
      <span
        >{{ timePeriod.name[0] }}
        <span style="font-size: 12px; margin-left: 5px"
          >({{ timePeriod.name[1] }})</span
        ></span
      >
    </div>
    <div
      :class="index % 2 === 0 ? 'even_item' : 'odd_item'"
      class="content-grid"
    >
      <span>{{ timePeriod.data[0] }}</span>
      <span v-if="!isMobile">{{ timePeriod.data[1] }}</span>
      <span v-if="!isMobile">{{ timePeriod.data[2] }}</span>
    </div>
  </div>
</template>

<script lang="ts" src="./GridForm.ts"></script>

<style scoped lang="scss">
@import "src/styles/index";
.pointer {
  cursor: pointer;
}
.arrow {
  position: absolute;
  right: 5%;
}
.grid {
  padding: 17px 0 0;
}
.span {
  padding: 13px;
}
.empty {
  span {
    display: block;
  }
}
.content-grid {
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 33px;
  @include md {
    grid-template-columns: 1fr;
  }
}

span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  height: 57px;
  display: flex;
  .time-period {
    flex-basis: 183px;
    display: flex;
    justify-content: center;
    span {
      color: $primary;
      font-size: 15px;
      p {
        font-size: 12px;
      }
    }
  }
}
.odd_row {
  background-color: #ffffff;
}
.even_row {
  background-color: $weak_secondary;
  font-weight: 700;
}

.even_item {
  span {
    background-color: $secondary;
  }
}
.odd_item {
  span {
    background-color: $almost_white;
  }
}
</style>
