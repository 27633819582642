import InputField from "../../components/input_field/InputField";
import GridForm from "../../components/form/GridForm";
import OptionButton from "../../components/button/OptionButton";

export default {
  name: "calculator-main",
  components: { InputField, GridForm, OptionButton },
  props: {
    isMobile: Boolean
  },
  data: () => ({
    price: null,
    tab: 1,
    // TODO calculator data. change if needed
    currency: "€",
    mwSt: 0.16,
    optionData: {
      leasing: [
        {
          months: 15,
          paymentFactor: 0.0700342857142857
        },
        {
          months: 24,
          paymentFactor: 0.0452685714285714
        },
        {
          months: 30,
          paymentFactor: 0.0373514285714286
        },
        {
          months: 36,
          paymentFactor: 0.03116
        },
        {
          months: 42,
          paymentFactor: 0.0285228571428571
        },
        {
          months: 48,
          paymentFactor: 0.0242571428571429
        },
        {
          months: 54,
          paymentFactor: 0.0223314285714286
        },
        {
          months: 60,
          paymentFactor: 0.0204028571428571
        },
        {
          months: 72,
          paymentFactor: 0.0177628571428571
        }
      ],
      finance: {
        percent: 0.0575,
        months: [6, 12, 18, 24, 30, 36, 42, 48, 54, 60, 66, 72]
      }
    },
    rowOneRerender: 0,
    costs: [
      {
        month: 60,
        terms: {
          monthly: 0,
          final: 0
        }
      },
      {
        month: 60,
        terms: {
          monthly: 0,
          final: 0
        }
      },
      {
        month: 60,
        terms: {
          monthly: 0,
          final: 0
        }
      }
    ]
  }),
  beforeMount() {
    this.changeCosts();
  },
  methods: {
    changeCosts: function() {
      // const localMw = 1 + this.mwSt;
      this.costs = this.costs.map(cost => {
        const monthIndex =
          this.tab === 1
            ? this.optionData.leasing.findIndex(i => i.months === cost.month)
            : this.optionData.finance.months.findIndex(i => i === cost.month);
        if (monthIndex !== -1 && this.tab === 1) {
          const nettoMonthly =
            this.price * this.optionData.leasing[monthIndex].paymentFactor;
          const nettoFinal =
            nettoMonthly * this.optionData.leasing[monthIndex].months;
          cost.terms.monthly = nettoMonthly;
          cost.terms.final = nettoFinal;
        } else if (monthIndex !== -1 && this.tab === 2) {
          // P = (Pv*R) / [1 - (1 + R)^(-n)
          const R = this.optionData.finance.percent / 12;
          const P =
            (this.price * R) /
            (1 - Math.pow(1 + R, -this.optionData.finance.months[monthIndex]));
          const final = P * this.optionData.finance.months[monthIndex];
          cost.terms.monthly = P;
          cost.terms.final = final;
        }
        return cost;
      });
    },
    changeTab: function(t) {
      this.tab = t;
    },
    changePrice: function(price) {
      this.price = price;
    },
    changeCurrentMonthCount: function(value, index) {
      this.costs[index].month = value;
      this.changeCosts();
    }
  },
  computed: {
    rowOne() {
      return this.costs.map(
        i => `${+i.terms.monthly.toFixed(2)} ${this.currency}`
      );
    },
    rowTwo() {
      return this.costs.map(
        i => `${+i.terms.final.toFixed(2)} ${this.currency}`
      );
    },
    months() {
      return this.costs.map(i => i.month);
    },
    uiMonths: function() {
      if (this.tab === 1) {
        return this.optionData.leasing.map(i => i.months);
      }
      return this.optionData.finance.months;
    }
  },
  watch: {
    // costs: {
    //   deep: true,
    //   handler: function(newVal) {
    //     console.log(newVal);
    //   }
    // },
    price: function() {
      this.changeCosts();
    },
    tab: function(newTab) {
      this.changeCosts();
      if (newTab === 2) {
        this.$emit("make-finance");
      } else {
        this.$emit("make-leasing");
      }
    }
  }
};
