import StaticHeader from "./sections/header/StaticHeader";
import CalculatorMain from "./sections/main/CalculatorMain";
import TextParagraph from "./sections/footer/TextParagraph";

const WIDTH = 1096;

export default {
  name: "App",
  components: { StaticHeader, CalculatorMain, TextParagraph },
  data: () => ({
    resChangedCount: 0,
    isLeasing: true
  }),
  beforeMount() {
    window.addEventListener("resize", () => {
      this.resChangedCount++;
    });
    if (document.body.getBoundingClientRect().width < WIDTH) {
      this.resChangedCount++;
    }
  },
  methods: {
    makeFinance() {
      this.isLeasing = false;
    },
    makeLeasing() {
      this.isLeasing = true;
    }
  },
  computed: {
    isMobile: function() {
      if (this.resChangedCount) {
        const rect = document.body.getBoundingClientRect();
        return rect.width < WIDTH;
      }
    }
  }
};
