import InputField from "../input_field/InputField";

export default {
  name: "select-item",
  components: { InputField },
  props: {
    items: Array,
    isOpen: Boolean,
    current: Number,
    searchedNumberItem: Number,
    value: Number
  },
  data: () => ({
    localItems: [],
    selected: true,
    isSelected: []
  }),
  beforeMount() {
    this.localItems = Array.from(
      { length: this.items.length },
      (_, k) => this.items[k]
    );
    this.isSelected = Array.from({ length: this.items.length }, () => false);
  },
  updated() {
    this.isSelected = Array.from({ length: this.items.length }, () => false);
    const index = this.localItems.indexOf(this.value);
    if (index !== -1) {
      this.isSelected[index] = true;
    }
  },
  methods: {
    clickOutside: function() {
      this.$emit("focus-out", this.current);
    },
    onSubmit: function(e) {
      e.preventDefault();
      this.clickOutside();
    },
    onSelect: function(item, key) {
      this.isSelected = this.isSelected.map(() => false);
      this.isSelected[key] = true;
      this.$emit("on-select", item, this.current);
      this.clickOutside();
    }
  },
  watch: {
    // searchedNumberItem: function(newVal) {
    //   if (newVal !== "" && newVal !== 0) {
    //     this.localItems = this.items.filter(i => i.toString().includes(newVal));
    //   } else {
    //     this.localItems = this.items;
    //   }
    // },
    items: {
      deep: true,
      handler(newVal) {
        this.localItems = newVal;
      }
    }
  }
};
